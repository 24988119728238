import React from 'react'
import useMediaQuery from 'utils/hooks/useMediaQuery';
import { LG } from '../../../OtherAttributesAccordion/constants/breakpoints';
import ExpirationCertificate from './components/ExpirationCertificate';
import CertificateLink from '../../../OtherAttributesAccordion/components/CertificateLink';
import { calculateCertificateFileExpired } from '../../../OtherAttributesAccordion/constants';

const RowWithCertificate = ({ certificateData }) => {
  const isMobile = useMediaQuery(LG)
  const { certificate, file_expires: expiresValue, file_not_expires: notExpiresValue  } = certificateData

  let resultExpiresValue;
  if (certificate)
    if (expiresValue || notExpiresValue) {
      resultExpiresValue = calculateCertificateFileExpired(expiresValue, notExpiresValue);
    }

  return (
    <>
      <CertificateLink link={certificate} /> {" - "}
      <div className='d-inline-block align-middle'>
        <ExpirationCertificate expiredData={resultExpiresValue} onlyCircleWithoutText={isMobile} />
      </div>
    </>
  )
}

export default RowWithCertificate