import { cleanDataForCertificates } from "../../../../SpecsTableRow/utils";

/**
 * Cleans and processes sustainability data for certificates.
 *
 * @param {Array} data - The raw sustainability data to be cleaned.
 * @param {boolean} areNotCertificates - Flag indicating if the data should be processed.
 * @returns {Array|undefined} - Returns an array of cleaned data if `areNotCertificates` is true, otherwise returns undefined.
 *
 * This function takes raw sustainability data and processes it if the `areNotCertificates` flag is true.
 * It uses the `cleanDataForCertificates` function to clean the data, then extracts and returns the second element
 * of each cleaned data entry.
 */
export const cleanedSustainabilityData = (data, areNotCertificates) => {
  if (!areNotCertificates) {
    return;
  }

  let storeTmpData = [];
  const cleanData = cleanDataForCertificates(data);
  
  cleanData.map((currentAttr) => {
    storeTmpData.push(currentAttr[1]);
  });
  
  return storeTmpData;
}

export const translatedSustainabilityLabel = (attrTitle, intl) => {
  return intl.formatMessage({ id: `FiltersBar.${attrTitle}FilterTitle` });
}