import React from "react";
import { useIntl } from "react-intl";
import CertificateLink from "../../../OtherAttributesAccordion/components/CertificateLink";
import { handleValidationBackingAttr } from "../../constants/handleAttrValidations";
import ExpirationCertificate from "../CertificateRow/components/ExpirationCertificate";
import { calculateCertificateFileExpired } from "../../../OtherAttributesAccordion/constants";
import { LG } from "../../../OtherAttributesAccordion/constants/breakpoints";
import useMediaQuery from "utils/hooks/useMediaQuery";
import { cleanedSustainabilityData, translatedSustainabilityLabel } from "./constants";
import SpecsTableColumn from "../../../SpecsTableRow/components/SpecsTableColumn";

const SustainabilityAttrRow = ({ currentAttr, currentGroup, certificateDomain }) => {
  const intl = useIntl();
  const isMobile = useMediaQuery(LG);
  const areNotCertificates = !currentGroup.areCertificates;

  let cleanedData = cleanedSustainabilityData(currentAttr, areNotCertificates); // Convert the currentAttr object in an array
  const isBackingProfile = handleValidationBackingAttr(cleanedData);

  if (isBackingProfile) {
    cleanedData[1] = <img src={`${certificateDomain}${cleanedData[3]}`} alt={cleanedData[0]} width={80} />
    cleanedData[3] = ""
  }

  // Validation for certificates expires data
  if (cleanedData && cleanedData[2] !== "") {
    // If have certificate but not have expires data or not expires data then the certificate is not valid
    if (!cleanedData[3]) {
      cleanedData[2] = ""
    } else {
      const expiresValue = cleanedData[3]
      const notExpiresValue = cleanedData[4]
      
      if (expiresValue || notExpiresValue) {
        cleanedData[3] = cleanedData[2]
  
        const resultExpiresValue = calculateCertificateFileExpired(expiresValue, notExpiresValue);
        cleanedData[2] = <ExpirationCertificate expiredData={resultExpiresValue} onlyCircleWithoutText={isMobile} isSustainabilityData /> ?? null
  
        // Clean the data for the certificate
        cleanedData[4] = null;
      }
    }
  }

  // Translate the sustainability label
  if (cleanedData) {
    cleanedData[0] = translatedSustainabilityLabel(cleanedData[0], intl);
  }

  cleanedData = cleanedData && cleanedData.filter((value) => value !== null);
  return (
    <tr>
      {cleanedData?.map((value, index) => {
        return <td key={`item-${value}-${index}`} width={"25%"}>
          {index === 3 && value ? (
            <SpecsTableColumn value={<CertificateLink link={`${certificateDomain}${value}`} />} />
          ) : (
            <SpecsTableColumn value={value} />
          )}
        </td>
      }) ?? null}
    </tr>
  );
}

export default SustainabilityAttrRow