import React from "react";
import { useIntl } from "react-intl";
import "scenes/ProductDetails/components/ProductSpecs/components/GeneralAccordion/styles/general-accordion.scss";
import useMediaQuery from "utils/hooks/useMediaQuery";
import { LG } from "../../constants/breakpoints";

const CertificateLink = ({ link }) => {
  const isMobile = useMediaQuery(LG);

  const intl = useIntl();
  const text = intl.formatMessage({
    id: "ProductSpecAccordionBody.certificate",
  });
  if (link === "") return <p></p>;
  return (
    <a
      className="d-inline ml-0 text-dark font-weight-bold"
      href={link}
      target="_blank"
      rel="noopener noreferrer"
    >
      <i className="fa fa-download" />
      {!isMobile && <span className="ml-2">{text}</span>}
    </a>
  );
};

export default CertificateLink;
