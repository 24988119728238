import classNames from 'classnames';
import React, { useEffect, useState, useMemo } from 'react';
import "./styles/ExpirationCertificate.scss";
import { Tooltip } from 'reactstrap';
import { EXPIRED_MESSAGE } from './constants';

let idCounter = 0;

const ExpirationCertificate = ({ expiredData, onlyCircleWithoutText, isCertificateRow = false }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [expiredMessage, setExpiredMessage] = useState("");
  
  // Generate a unique ID for the tooltip
  const tooltipId = useMemo(() => `expiredDataTooltip_sustainability_${idCounter++}`, []);
  
  const toggle = (value) => setTooltipOpen(value);

  const circleBgRed = !expiredData.notExpiresValue && expiredData.timeRemainingUntilExpiration <= 1 && !expiredData.remainingOneHalfYear;
  const circleBgGreen = expiredData.timeRemainingUntilExpiration >= 2;
  const circleBgOrange = expiredData.timeRemainingUntilExpiration === 1 && expiredData.remainingOneHalfYear;
  const circleBgGray = expiredData.notExpiresValue;
  
  const handleColorAndMessageOfExpirationCircle = () => {
    if (circleBgRed) return "littleTimeToExpires";
    if (circleBgGreen) return "goodTimeToExpires";
    if (circleBgOrange) return "mediumTimeToExpires";
    if (circleBgGray) return "notExpires";
  }
  
  useEffect(() => {
    setExpiredMessage(handleColorAndMessageOfExpirationCircle());
  }, [expiredData]); // Update on expiredData change

  return (
    <div className={`d-flex justify-content-${isCertificateRow ? "center" : "start"}`}>
      {!onlyCircleWithoutText && <span className={`px-lg-0 mr-2 ${isCertificateRow && "smaller-fs"}`}>
        {expiredData.formattedExpiresDate}
      </span>}
      <div 
        className={classNames({
          'd-inline-block rounded-circle ml-0 align-self-start cicle-expired': true,
          "bg-danger": circleBgRed,
          "bg-success": circleBgGreen,
          "bg-warning": circleBgOrange,
          "bg-gray": circleBgGray
        })}
        id={tooltipId}
        onMouseOver={() => toggle(true)}
        onMouseLeave={() => toggle(false)}
      >
      </div>
      <div className='d-inline-block d-flex align-items-start relative'>
        <span
          className="d-inline-block expiration-certificate--tooltip relative"
          tabIndex="0"
          id={tooltipId}
          onMouseOver={() => toggle(true)}
          onMouseLeave={() => toggle(false)}
        >
          <span 
            className={classNames({
              'expirate-certification--icon-info': true,
              "expirate-danger": circleBgRed,
              "expirate-success": circleBgGreen,
              "expirate-warning": circleBgOrange
            })}>
              ⓘ
          </span>
          <Tooltip
            key={tooltipId}
            placement={"top"}
            isOpen={tooltipOpen}
            target={tooltipId}
            className={classNames({
              "expiration-certificate--tooltip-message": !onlyCircleWithoutText,
              "expiration-certificate--tooltip-message-mobile-sustainability": onlyCircleWithoutText
            })}
          >
            {EXPIRED_MESSAGE(expiredData, expiredMessage, onlyCircleWithoutText)}
          </Tooltip>
        </span>
      </div>
    </div>
  );
}

export default ExpirationCertificate;