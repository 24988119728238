import React from 'react'
import { separeDataByGroups } from './constants/separeDataByGroups'
import CertificateRow from './components/CertificateRow';
import "./styles/SustainabilityAttributesAccordion.scss"
import { deleteGuionFromAttributes } from './constants/handleAttrValidations';
import { singlePatternOtherAttributesQuery } from 'scenes/ProductDetails/queries';
import { compose, graphql } from 'react-apollo';
import { SUSTAINABILITY_ATTRIBUTES } from './constants/attributesByGroups';
import SustainabilityAttrRow from './components/SustainabilityAttrRow';

const SustainabilityAttributesAccordion = ({ 
  data,
  patternId,
  singlePatternOtherAttributesQuery
}) => {
  const otherattributes = singlePatternOtherAttributesQuery?.pattern?.otherattributes
  const { packagingFsc, sustainability, manufacturingCertifications } = data
  const mainDataToShow = separeDataByGroups(sustainability, manufacturingCertifications, packagingFsc)

  return (
    <section>
      {
        mainDataToShow.map((group) => {
          return <React.Fragment key={`item-${ group.id }`}>
            <table className='border border-2 mt-2 w-100'>
              <tr>
                <td className='font-weight-bold' colSpan={4}>{group.title}</td>
              </tr>
              {group.attrs.map((attr) => {
                if (attr.title === SUSTAINABILITY_ATTRIBUTES[group.title.key]?.antimicrobial || attr.title === SUSTAINABILITY_ATTRIBUTES[group.title.key]?.biobasedPvcBioAttributed) {
                  attr.value = ""
                  attr.certificateNotExpires = ""
                }

                deleteGuionFromAttributes(attr);
                return <SustainabilityAttrRow
                  key={`item__${attr.id}`}
                  currentAttr={attr}
                  currentGroup={group}
                  certificateDomain={otherattributes?.certificateDomain}
                />
              })}
              {group.areCertificates &&
                <td colSpan={4}>
                  <CertificateRow
                    groupTitle={group.title.key}
                    attrs={group.attrs}
                    link={otherattributes?.certificateDomain}
                  />
                </td>}
            </table>
          </React.Fragment>
        })
      }
    </section>
  )
}

export default compose(
  graphql(singlePatternOtherAttributesQuery, {
    name: "singlePatternOtherAttributesQuery",
    options: (props) => ({
      variables: {
        id: props?.patternId
      },
      fetchPolicy: 'no-cache', // or 'network-only'
    })
  })
)(SustainabilityAttributesAccordion);