import React from 'react'
import PropTypes from "prop-types"
import "./styles/CertificateRow.scss"
import { FormattedMessage } from 'react-intl'
import { CERTIFICATIONS } from '../../constants/attributesByGroups'
import { LG } from '../../../OtherAttributesAccordion/constants/breakpoints'
import useMediaQuery from 'utils/hooks/useMediaQuery'
import { calculateCertificateFileExpired } from '../../../OtherAttributesAccordion/constants'
import ExpirationCertificate from './components/ExpirationCertificate'
import RenderCertificateImg from './components/RenderCertificateImg'

const CertificateRow = ({ groupTitle, link, attrs }) => {
  const isMobile = useMediaQuery(LG);

  return (
    <div className=''>
      {groupTitle === CERTIFICATIONS && <div className='col-12'>
        <h6 className='certificate--row_note mb-4'>
          * {" "}
          <FormattedMessage 
            id="ProductSpecs.sustainabilityAttributes.showCertificate"
            defaultMessage="To view the certification document, click on the PDF logo"
          />
        </h6>
      </div>}
      <div className='d-flex align-items-center flex-wrap col-12 m-0 p-0'>
        {attrs?.map((sustainabilityProperty) => {
          let fieldWithExpirateCertification;
          const haveCertificate = sustainabilityProperty?.certificate

          if (haveCertificate) {
            const expiresValue = sustainabilityProperty?.certificateExpiresDate
            const notExpiresValue = sustainabilityProperty?.certificateNotExpires ?? false

            if (expiresValue || notExpiresValue) {
              const resultExpiresValue = calculateCertificateFileExpired(expiresValue, notExpiresValue);
              fieldWithExpirateCertification = <ExpirationCertificate expiredData={resultExpiresValue} onlyCircleWithoutText={isMobile} isCertificateRow /> ?? null
            }
          }

          const notHaveCertificate = sustainabilityProperty.certificate === ""

          return <RenderCertificateImg
            key={sustainabilityProperty.title}
            link={link}
            sustainabilityProperty={sustainabilityProperty}
            fieldWithExpirateCertification={fieldWithExpirateCertification}
            notHaveCertificate={notHaveCertificate}
          />
        })}
      </div>
    </div>
  );
};

CertificateRow.propTypes = {
  groupTitle: PropTypes.string.isRequired,
  attrs: PropTypes.array.isRequired,
  link: PropTypes.string.isRequired,
};

export default CertificateRow;
