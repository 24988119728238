import { SUSTAINABILITY_ATTRIBUTES } from "./attributesByGroups"

export const deleteGuionFromAttributes = (attr) => {
  if (
    typeof attr.value === "string" && 
    attr.title !== SUSTAINABILITY_ATTRIBUTES["attributes"].epd
    ) {
    let valueWithoutGuion = attr.value.split('_').join(' ')
    attr.value = valueWithoutGuion
  }
}

export const handleValidationBackingAttr = (data) => {
  let attrTitle = data ? data[0] : null;

  if (attrTitle === SUSTAINABILITY_ATTRIBUTES["attributes"].backingProfile) {
    return true
  }
}